/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { City } from './City';
import {
    CityFromJSON,
    CityFromJSONTyped,
    CityToJSON,
} from './City';
import type { DevicePlatformEnum } from './DevicePlatformEnum';
import {
    DevicePlatformEnumFromJSON,
    DevicePlatformEnumFromJSONTyped,
    DevicePlatformEnumToJSON,
} from './DevicePlatformEnum';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { SexEnum } from './SexEnum';
import {
    SexEnumFromJSON,
    SexEnumFromJSONTyped,
    SexEnumToJSON,
} from './SexEnum';
import type { UtmMediumEnum } from './UtmMediumEnum';
import {
    UtmMediumEnumFromJSON,
    UtmMediumEnumFromJSONTyped,
    UtmMediumEnumToJSON,
} from './UtmMediumEnum';

/**
 * 
 * @export
 * @interface PatchedUser
 */
export interface PatchedUser {
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    first_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    last_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly full_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly short_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly phone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    email?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof PatchedUser
     */
    birthday?: Date;
    /**
     * 
     * @type {number}
     * @memberof PatchedUser
     */
    readonly age?: number;
    /**
     * 
     * @type {SexEnum}
     * @memberof PatchedUser
     */
    sex?: SexEnum | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly avatar_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    registered?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatchedUser
     */
    date_joined?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatchedUser
     */
    last_app_open?: Date | null;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof PatchedUser
     */
    readonly feature_flags?: { [key: string]: boolean; };
    /**
     * 
     * @type {number}
     * @memberof PatchedUser
     */
    current_city?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PatchedUser
     */
    home_city?: number;
    /**
     * 
     * @type {Array<City>}
     * @memberof PatchedUser
     */
    readonly cities?: Array<City>;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    readonly is_pro?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    readonly has_active_subscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    readonly has_past_due_subscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    readonly has_upcoming_subscription?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    readonly free_promo_active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof PatchedUser
     */
    readonly free_promo_end_date?: Date;
    /**
     * 
     * @type {Array<Organization>}
     * @memberof PatchedUser
     */
    readonly organizations?: Array<Organization>;
    /**
     * 
     * @type {UtmMediumEnum}
     * @memberof PatchedUser
     */
    utm_medium?: UtmMediumEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    set_referral_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly accepted_referral_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly accepted_referral_name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly referral_code?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    custom_referral_code?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    readonly trial_available?: boolean;
    /**
     * A user can cancel a subscription if they have an active or trialing subscription that has not been cancelled.
     * @type {boolean}
     * @memberof PatchedUser
     */
    readonly cancel_available?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    use_metric?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUser
     */
    participate_in_contests?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedUser
     */
    readonly favorite_businesses?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedUser
     */
    readonly deal_requests?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PatchedUser
     */
    readonly favorite_menu_items?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof PatchedUser
     */
    readonly redemption_count?: number;
    /**
     * Get any active redemption locks and their expiration datetimes.
     * 
     * Any redemptions within the last 24 hours are possibilities. The latest redemption takes precedence.
     * @type {{ [key: string]: Date; }}
     * @memberof PatchedUser
     */
    readonly redemption_locks?: { [key: string]: Date; };
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    device_token?: string;
    /**
     * 
     * @type {DevicePlatformEnum}
     * @memberof PatchedUser
     */
    device_platform?: DevicePlatformEnum;
    /**
     * 
     * @type {string}
     * @memberof PatchedUser
     */
    readonly intercom_hash?: string;
}

/**
 * Check if a given object implements the PatchedUser interface.
 */
export function instanceOfPatchedUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchedUserFromJSON(json: any): PatchedUser {
    return PatchedUserFromJSONTyped(json, false);
}

export function PatchedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'first_name': !exists(json, 'first_name') ? undefined : json['first_name'],
        'last_name': !exists(json, 'last_name') ? undefined : json['last_name'],
        'full_name': !exists(json, 'full_name') ? undefined : json['full_name'],
        'short_name': !exists(json, 'short_name') ? undefined : json['short_name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'birthday': !exists(json, 'birthday') ? undefined : (new Date(json['birthday'])),
        'age': !exists(json, 'age') ? undefined : json['age'],
        'sex': !exists(json, 'sex') ? undefined : SexEnumFromJSON(json['sex']),
        'avatar_url': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
        'date_joined': !exists(json, 'date_joined') ? undefined : (new Date(json['date_joined'])),
        'last_app_open': !exists(json, 'last_app_open') ? undefined : (json['last_app_open'] === null ? null : new Date(json['last_app_open'])),
        'feature_flags': !exists(json, 'feature_flags') ? undefined : json['feature_flags'],
        'current_city': !exists(json, 'current_city') ? undefined : json['current_city'],
        'home_city': !exists(json, 'home_city') ? undefined : json['home_city'],
        'cities': !exists(json, 'cities') ? undefined : ((json['cities'] as Array<any>).map(CityFromJSON)),
        'is_pro': !exists(json, 'is_pro') ? undefined : json['is_pro'],
        'has_active_subscription': !exists(json, 'has_active_subscription') ? undefined : json['has_active_subscription'],
        'has_past_due_subscription': !exists(json, 'has_past_due_subscription') ? undefined : json['has_past_due_subscription'],
        'has_upcoming_subscription': !exists(json, 'has_upcoming_subscription') ? undefined : json['has_upcoming_subscription'],
        'free_promo_active': !exists(json, 'free_promo_active') ? undefined : json['free_promo_active'],
        'free_promo_end_date': !exists(json, 'free_promo_end_date') ? undefined : (new Date(json['free_promo_end_date'])),
        'organizations': !exists(json, 'organizations') ? undefined : ((json['organizations'] as Array<any>).map(OrganizationFromJSON)),
        'utm_medium': !exists(json, 'utm_medium') ? undefined : UtmMediumEnumFromJSON(json['utm_medium']),
        'set_referral_code': !exists(json, 'set_referral_code') ? undefined : json['set_referral_code'],
        'accepted_referral_code': !exists(json, 'accepted_referral_code') ? undefined : json['accepted_referral_code'],
        'accepted_referral_name': !exists(json, 'accepted_referral_name') ? undefined : json['accepted_referral_name'],
        'referral_code': !exists(json, 'referral_code') ? undefined : json['referral_code'],
        'custom_referral_code': !exists(json, 'custom_referral_code') ? undefined : json['custom_referral_code'],
        'trial_available': !exists(json, 'trial_available') ? undefined : json['trial_available'],
        'cancel_available': !exists(json, 'cancel_available') ? undefined : json['cancel_available'],
        'use_metric': !exists(json, 'use_metric') ? undefined : json['use_metric'],
        'participate_in_contests': !exists(json, 'participate_in_contests') ? undefined : json['participate_in_contests'],
        'favorite_businesses': !exists(json, 'favorite_businesses') ? undefined : json['favorite_businesses'],
        'deal_requests': !exists(json, 'deal_requests') ? undefined : json['deal_requests'],
        'favorite_menu_items': !exists(json, 'favorite_menu_items') ? undefined : json['favorite_menu_items'],
        'redemption_count': !exists(json, 'redemption_count') ? undefined : json['redemption_count'],
        'redemption_locks': !exists(json, 'redemption_locks') ? undefined : json['redemption_locks'],
        'device_token': !exists(json, 'device_token') ? undefined : json['device_token'],
        'device_platform': !exists(json, 'device_platform') ? undefined : DevicePlatformEnumFromJSON(json['device_platform']),
        'intercom_hash': !exists(json, 'intercom_hash') ? undefined : json['intercom_hash'],
    };
}

export function PatchedUserToJSON(value?: PatchedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_name': value.first_name,
        'last_name': value.last_name,
        'email': value.email,
        'birthday': value.birthday === undefined ? undefined : (value.birthday.toISOString().substr(0,10)),
        'sex': SexEnumToJSON(value.sex),
        'registered': value.registered,
        'date_joined': value.date_joined === undefined ? undefined : (value.date_joined.toISOString()),
        'last_app_open': value.last_app_open === undefined ? undefined : (value.last_app_open === null ? null : value.last_app_open.toISOString()),
        'current_city': value.current_city,
        'home_city': value.home_city,
        'utm_medium': UtmMediumEnumToJSON(value.utm_medium),
        'set_referral_code': value.set_referral_code,
        'custom_referral_code': value.custom_referral_code,
        'use_metric': value.use_metric,
        'participate_in_contests': value.participate_in_contests,
        'device_token': value.device_token,
        'device_platform': DevicePlatformEnumToJSON(value.device_platform),
    };
}

