/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DealReward } from './DealReward';
import {
    DealRewardFromJSON,
    DealRewardFromJSONTyped,
    DealRewardToJSON,
} from './DealReward';
import type { Type97cEnum } from './Type97cEnum';
import {
    Type97cEnumFromJSON,
    Type97cEnumFromJSONTyped,
    Type97cEnumToJSON,
} from './Type97cEnum';

/**
 * 
 * @export
 * @interface Deal
 */
export interface Deal {
    /**
     * 
     * @type {number}
     * @memberof Deal
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof Deal
     */
    readonly name: string;
    /**
     * 
     * @type {string}
     * @memberof Deal
     */
    readonly description: string;
    /**
     * 
     * @type {Type97cEnum}
     * @memberof Deal
     */
    readonly type: Type97cEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof Deal
     */
    readonly includes_alcohol: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Deal
     */
    readonly allow_dine_in: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Deal
     */
    readonly allow_takeout: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Deal
     */
    readonly allow_call_in: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Deal
     */
    readonly allow_mix_and_match: boolean;
    /**
     * For deals of type 'Spend Amount', set the minimum amount that must be spent.
     * @type {string}
     * @memberof Deal
     */
    readonly spend_amount: string | null;
    /**
     * 
     * @type {Array<DealReward>}
     * @memberof Deal
     */
    readonly rewards: Array<DealReward>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof Deal
     */
    readonly hours: { [key: string]: Array<string>; };
    /**
     * 
     * @type {boolean}
     * @memberof Deal
     */
    readonly is_active: boolean;
    /**
     * Determines when this deal will be active next, based on the business's timezone.
     * @type {string}
     * @memberof Deal
     */
    readonly next_active: string | null;
    /**
     * 
     * @type {string}
     * @memberof Deal
     */
    readonly business_id: string;
    /**
     * 
     * @type {string}
     * @memberof Deal
     */
    readonly business_name: string;
    /**
     * 
     * @type {string}
     * @memberof Deal
     */
    readonly promotion_name: string;
    /**
     * The image to show when promoting this deal. Shown in notifications, etc.
     * @type {string}
     * @memberof Deal
     */
    readonly promotion_image: string | null;
    /**
     * The wide version of the image to show when promoting this deal. Shown in example order cards, etc.
     * @type {string}
     * @memberof Deal
     */
    readonly promotion_image_wide: string | null;
    /**
     * Show an example order for this deal throughout the app.
     * @type {boolean}
     * @memberof Deal
     */
    readonly show_example: boolean;
    /**
     * The example order for this deal. (e.g. '2 Entrees'))
     * @type {string}
     * @memberof Deal
     */
    readonly example_order: string;
    /**
     * The example order's total price at the business using SLOeats.
     * @type {string}
     * @memberof Deal
     */
    readonly example_order_price_sloeats: string | null;
    /**
     * The example order's total price at the business without using SLOeats.
     * @type {string}
     * @memberof Deal
     */
    readonly example_order_price_business: string | null;
    /**
     * The example order's total price using DoorDash.
     * @type {string}
     * @memberof Deal
     */
    readonly example_order_price_doordash: string | null;
    /**
     * 
     * @type {string}
     * @memberof Deal
     */
    readonly example_order_breakdown: string;
    /**
     * 
     * @type {boolean}
     * @memberof Deal
     */
    readonly is_archived: boolean;
}

/**
 * Check if a given object implements the Deal interface.
 */
export function instanceOfDeal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "includes_alcohol" in value;
    isInstance = isInstance && "allow_dine_in" in value;
    isInstance = isInstance && "allow_takeout" in value;
    isInstance = isInstance && "allow_call_in" in value;
    isInstance = isInstance && "allow_mix_and_match" in value;
    isInstance = isInstance && "spend_amount" in value;
    isInstance = isInstance && "rewards" in value;
    isInstance = isInstance && "hours" in value;
    isInstance = isInstance && "is_active" in value;
    isInstance = isInstance && "next_active" in value;
    isInstance = isInstance && "business_id" in value;
    isInstance = isInstance && "business_name" in value;
    isInstance = isInstance && "promotion_name" in value;
    isInstance = isInstance && "promotion_image" in value;
    isInstance = isInstance && "promotion_image_wide" in value;
    isInstance = isInstance && "show_example" in value;
    isInstance = isInstance && "example_order" in value;
    isInstance = isInstance && "example_order_price_sloeats" in value;
    isInstance = isInstance && "example_order_price_business" in value;
    isInstance = isInstance && "example_order_price_doordash" in value;
    isInstance = isInstance && "example_order_breakdown" in value;
    isInstance = isInstance && "is_archived" in value;

    return isInstance;
}

export function DealFromJSON(json: any): Deal {
    return DealFromJSONTyped(json, false);
}

export function DealFromJSONTyped(json: any, ignoreDiscriminator: boolean): Deal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'type': Type97cEnumFromJSON(json['type']),
        'includes_alcohol': json['includes_alcohol'],
        'allow_dine_in': json['allow_dine_in'],
        'allow_takeout': json['allow_takeout'],
        'allow_call_in': json['allow_call_in'],
        'allow_mix_and_match': json['allow_mix_and_match'],
        'spend_amount': json['spend_amount'],
        'rewards': ((json['rewards'] as Array<any>).map(DealRewardFromJSON)),
        'hours': json['hours'],
        'is_active': json['is_active'],
        'next_active': json['next_active'],
        'business_id': json['business_id'],
        'business_name': json['business_name'],
        'promotion_name': json['promotion_name'],
        'promotion_image': json['promotion_image'],
        'promotion_image_wide': json['promotion_image_wide'],
        'show_example': json['show_example'],
        'example_order': json['example_order'],
        'example_order_price_sloeats': json['example_order_price_sloeats'],
        'example_order_price_business': json['example_order_price_business'],
        'example_order_price_doordash': json['example_order_price_doordash'],
        'example_order_breakdown': json['example_order_breakdown'],
        'is_archived': json['is_archived'],
    };
}

export function DealToJSON(value?: Deal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

