import dayjs from "dayjs"
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"
import { useCallback, useEffect, useRef, useState } from "react"

import { ExpirationStatuses } from "../types/User"

dayjs.extend(isSameOrBefore)

export default function useExpirationCheck(expirations: { [key: string]: string | Date }): ExpirationStatuses {
    const [expirationStatuses, setExpirationStatuses] = useState<ExpirationStatuses>({})
    const minutes = useRef<number>(new Date().getMinutes())

    useEffect(() => {
        if (Object.keys(expirations).length !== 0) {
            const statuses = {}
            Object.entries(expirations).forEach(
                ([businessID, expiration]) => (statuses[businessID] = dayjs(expiration).isSameOrBefore(new Date())),
            )
            setExpirationStatuses(statuses)
        }
    }, [expirations])

    const checkExpirations = useCallback(() => {
        if (Object.keys(expirations).length !== 0) {
            const now = new Date().getMinutes()

            // Only run the expensive check at the top of each minute
            if (now > minutes.current) {
                const newExpirationStatuses = {}
                let anyChanged = false

                Object.entries(expirations).forEach(([businessID, expiration]) => {
                    const isExpired = dayjs(expiration).isSameOrBefore(new Date())
                    anyChanged = anyChanged || expirationStatuses[businessID] != isExpired
                    newExpirationStatuses[businessID] = isExpired
                })

                if (anyChanged) {
                    setExpirationStatuses(newExpirationStatuses)
                }
            }

            minutes.current = now
        }
    }, [expirations, expirationStatuses])

    useEffect(() => {
        const intervalId = setInterval(checkExpirations, 1000)
        return () => clearInterval(intervalId)
    }, [checkExpirations])

    return expirationStatuses
}
