/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LightweightDealReward } from './LightweightDealReward';
import {
    LightweightDealRewardFromJSON,
    LightweightDealRewardFromJSONTyped,
    LightweightDealRewardToJSON,
} from './LightweightDealReward';
import type { Type97cEnum } from './Type97cEnum';
import {
    Type97cEnumFromJSON,
    Type97cEnumFromJSONTyped,
    Type97cEnumToJSON,
} from './Type97cEnum';

/**
 * 
 * @export
 * @interface DealWithRewards
 */
export interface DealWithRewards {
    /**
     * 
     * @type {number}
     * @memberof DealWithRewards
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof DealWithRewards
     */
    readonly name: string;
    /**
     * 
     * @type {Type97cEnum}
     * @memberof DealWithRewards
     */
    readonly type: Type97cEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof DealWithRewards
     */
    readonly includes_alcohol: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DealWithRewards
     */
    readonly created_at: Date;
    /**
     * 
     * @type {Array<LightweightDealReward>}
     * @memberof DealWithRewards
     */
    readonly rewards: Array<LightweightDealReward>;
    /**
     * 
     * @type {boolean}
     * @memberof DealWithRewards
     */
    readonly is_archived: boolean;
}

/**
 * Check if a given object implements the DealWithRewards interface.
 */
export function instanceOfDealWithRewards(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "includes_alcohol" in value;
    isInstance = isInstance && "created_at" in value;
    isInstance = isInstance && "rewards" in value;
    isInstance = isInstance && "is_archived" in value;

    return isInstance;
}

export function DealWithRewardsFromJSON(json: any): DealWithRewards {
    return DealWithRewardsFromJSONTyped(json, false);
}

export function DealWithRewardsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealWithRewards {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': Type97cEnumFromJSON(json['type']),
        'includes_alcohol': json['includes_alcohol'],
        'created_at': (new Date(json['created_at'])),
        'rewards': ((json['rewards'] as Array<any>).map(LightweightDealRewardFromJSON)),
        'is_archived': json['is_archived'],
    };
}

export function DealWithRewardsToJSON(value?: DealWithRewards | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

